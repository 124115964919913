header.menu-hide {
  color: #000;
  .menu-wrapper {
    left: 100%;
  }
}
header.menu-show {
  color: #fff;
  .menu-wrapper {
    left: 0px;
  }
}
header {
  background: $bg-white;
  padding: 15px 0;
  width: 100%;
  top: 0;
  position: fixed;
  height: 85px;
  left: 0;
  z-index: 3;

  @include respond-to('tablet-large') {
    overflow-x: hidden;
    height: 70px;
    .logo-link{
      height: 40px;
      img{
        height: 100%;
        object-position: left;
        object-fit: contain;
      }
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  .menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;

    @include respond-to('tablet-large') {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      position: fixed;
      left: 100%;
      bottom: 0;
      top: 70px;
      width: 100%;
      background-color: $bg-green;
      transition: left .2s ease-in-out;
      z-index: 2;
      padding: 30px 15px;

      .nav {
        grid-auto-flow: row;
        align-self: flex-start;
        margin-bottom: 55px;
        gap: 15px;
        a {
          color: #fff;
          font-size: 2.6rem;
        }
      }

      .header-contacts {
        align-items: flex-start;

        svg path {
          fill: #fff;
        }
        .phone-link{
          color: #fff;
          font-size: 3.2rem;
          margin-bottom: 22px;
        }
      }

    }
  }

  .nav {
    align-self: flex-end;
    display: grid;
    gap: 30px;
    grid-auto-flow: column;
    margin: 0 auto;

    @include respond-to('tablet-large') {
      margin: 0;
    }

    a {
      font-family: $font-opensans;
      font-weight: 600;
      color: $color-tertiary;
      transition: color .2s ease-in-out;      
      &:hover {
        color: $bg-green;
      }
    }
  }

  .header-contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .phone-link {
    font-family: $font-oswald;
    font-size: 2.6rem;
    letter-spacing: 0.05em;
    font-weight: 700;
    color: $bg-orange;
    margin-bottom: 5px;
  }

  .header-contacts_buttons {
    display: grid;
    gap: 15px;
    grid-auto-flow: column;
    @include respond-to('tablet-large'){
      gap: 35px;
      svg{
        width: auto;
        height: 36px;
      }
    }
  }

  .menu-button.toggle {
    display: none;
    flex-direction: column;
    align-self: center;
    // width: 40px;
    // height: 40px;
    background-color: #fff;

    // align-self: flex-end;
    @include respond-to('tablet-large') {
      display: flex;
      align-self: center;
    }
  }

  $color: $bg-green;
  $active: #363636;

  .toggle {
    display: block;
    cursor: pointer;

    input {
      display: none;

      &+div {
        width: 20px;
        height: 14px;
        position: relative;

        div {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          transition: transform .5s ease;

          span {
            display: block;
            position: absolute;
            left: 0;
            right: 0;

            &:first-child {
              top: 0;

              &:before,
              &:after {
                top: 0;
              }
            }

            &:last-child {
              bottom: 0;

              &:before,
              &:after {
                bottom: 0;
              }
            }

            &:before,
            &:after {
              content: '';
              display: block;
              width: 47%;
              height: 2px;
              border-radius: 1px;
              background: $color;
              position: absolute;
              -webkit-backface-visibility: hidden;
              transition: transform .5s ease, border-radius .3s ease, background .4s ease;
            }

            &:before {
              left: 0;
              transform-origin: 0 50%;
              transform: translate(1px, 0) scaleX(1.1);
            }

            &:after {
              right: 0;
              transform-origin: 100% 50%;
              transform: translate(-1px, 0) scaleX(1.1);
            }
          }
        }

        svg {
          display: block;
          fill: none;
          stroke: $color;
          stroke-width: 2px;
          width: 44px;
          height: 44px;
          stroke-linecap: round;
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -22px 0 0 -22px;
          stroke-dasharray: 0 82.801 8 82.801;
          stroke-dashoffset: 82.801;
          transform-origin: 50% 50%;
          -webkit-backface-visibility: hidden;
          transform: scale(1);
          transition: stroke-dashoffset .5s ease, stroke-dasharray .6s ease, transform .5s ease, stroke .4s ease;

          &:nth-child(3) {
            transform: rotate(180deg) scale(1);
          }
        }
      }

      &:checked+div {
        div {
          transform: rotate(90deg);

          span {

            &:before,
            &:after {
              background: $active;
            }

            &:first-child {
              &:before {
                transform: rotate(45deg) translate(2.2px, -3px) scaleX(1.05);
              }

              &:after {
                transform: rotate(-45deg) translate(-2.2px, -3px) scaleX(1.05);
              }
            }

            &:last-child {
              &:before {
                transform: rotate(-45deg) translate(2.2px, 3px) scaleX(1.05);
              }

              &:after {
                transform: rotate(45deg) translate(-2.2px, 3px) scaleX(1.05);
              }
            }
          }
        }

        svg {
          stroke-dashoffset: 62;
          stroke-dasharray: 0 82.801 62 82.801;
          transform: rotate(90deg);
          stroke: $active;

          &:nth-child(3) {
            transform: rotate(270deg);
          }
        }
      }
    }
  }
}