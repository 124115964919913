#wares{    
    .wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        grid-column-gap: 30px;
        grid-row-gap: 60px;
        
        @include respond-to('tablet-large') {
            grid-template-columns: 1fr;
            
        }
        @include respond-to('mobile-large') {
            grid-row-gap: 30px;
        }
    }
    h2{
        grid-column: 1/3;
        @include respond-to('tablet-large') {
            grid-column: 1/2;
            // margin-bottom: 0px;
        }
        width: 100%;
        @include backgrounded($bg-orange);
        // font-size: 5.2rem;
        max-width: 540px;
        text-align: center;        
        // margin-bottom: 60px;
        letter-spacing: 0.05em;
        @include respond-to('mobile-large') {
            width: 100%;
            font-size: 2.8rem;
            padding: 11px 15px;
            height: auto;
        }
    }
    p{
        align-self: center;
        // padding: 60px 0;
        font-weight: 600;
        line-height: 1.4;
        color: $color-primary;
        // font-size: 1.6rem;
        text-indent: 20px;
    }
    .gallery{
        display: grid;
        grid-template-columns: 5;
        grid-gap: 20px;
        grid-auto-rows: max-content;
        .primary-image{
            grid-column: 1/6;            
        }
        .primary-image,
        .secondary-image{
            border-radius: 10px;
            overflow: hidden;
        }      
        @include respond-to('mobile-large') {
            grid-gap: 5px;
        }  
    }
    span{
        color: $bg-orange;
    }
}