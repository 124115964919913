#tech{
    background-color: $bg-green;
    .wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        grid-gap: 45px 30px;
        @include respond-to('tablet-large') {
            grid-template-columns: 1fr;
            grid-gap: 15px;
        }
        @include respond-to('mobile-large') {
            grid-gap: 30px;
        }
    }
    h2{
        grid-column: 2/3;
        @include respond-to('tablet-large') {
            grid-column: 1/3;
            justify-self: center;
        }
        @include backgrounded($bg-orange);
        // font-size: 5.2rem;
        max-width: 350px;
        width: 100%;
        text-align: center;   
        margin-bottom: 15px;    
        letter-spacing: 0.05em;
        @include respond-to('mobile-large') {
            width: 100%;
            font-size: 2.8rem;
            padding: 11px 15px;
            height: auto;
            margin-bottom: 0px;    
        } 
    }
    .text{
        align-self: center;
        padding: 15px 0;
        @include respond-to('mobile-large') {
            padding: 0px;
            grid-column: 1/3;
        }
    }
    p{
        align-self: center;
        font-weight: 600;
        line-height: 1.4;        
        text-indent: 20px;        
        color: #fff;        
    }
    .tech__image{
        @include respond-to('tablet-large') {
            grid-row: 2/3;
            grid-column: 1/3;
        }
    }
}