//! import google fonts
// @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700');
// OR use @font-face like below
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&family=Oswald:wght@500;600;700&display=swap');


//! set font variables
$font-opensans: 'Open Sans', sans-serif;
$font-oswald: 'Oswald', sans-serif;

html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
  // @include respond-to('mobile-large') {
  //   font-size: 50%;
  // }
}

body {
  font-family: $font-opensans;
  font-size: 1.4rem;
  color: $color-primary;
}

