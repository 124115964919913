.main-banner{    
    
    // padding: 30px 0;
    margin-top: 85px;
    position: relative;
    z-index: 2;
    // overflow: hidden;
    @include respond-to('tablet-large'){
        margin-top: 70px;
    }
    .wrapper{        
        height: 100%;
    }
    .video-wrap{
        position: absolute;
        top: 0; 
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
    video{
        object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;        
    }
    .main-banner__text{        
        // position: absolute;
        // top: 50%;
        // left:0;
        width: 100%;
        z-index: 2;
        position: relative;
        // transform: translateY(-50%);
        padding: 120px 0;
        background-color: rgba($color: #000000, $alpha: 0.5);
        @include respond-to('tablet') {
            // font-size: 2.4rem;
            padding: 60px 0;
            background-image: url('images/main-banner.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        @include respond-to('mobile-large') {
            padding: 30px 0;
        }
    }
    .title{
        display: inline-block;
        font-family: $font-oswald;
        text-transform: uppercase;
        font-weight: 500;
        color: #fff;
        font-size: 5rem;
        padding-bottom: 30px;
        border-bottom: 5px solid #fff;
        @include respond-to('tablet') {
            font-size: 5rem;            
        }
        @include respond-to('mobile-large') {
            font-size: 12vw;
            padding-bottom: 15px;
        }
    }
    .subtitle_1{
        font-family: $font-oswald;
        text-transform: uppercase;
        font-weight: 500;
        color: #fff;
        font-size: 4.5rem;  
        padding-top: 30px;
        @include respond-to('tablet') {
            font-size: 4.5rem;            
        }
        @include respond-to('mobile-large') {
            font-size: 10vw;
            padding-top: 15px;
        }
        
    }
    .subtitle_2{
        font-family: $font-oswald;
        text-transform: uppercase;
        font-weight: 600;
        color: #fff;
        font-size: 8.6rem; 
        @include respond-to('tablet') {
            font-size: 8.6rem;         
        }
        @include respond-to('mobile-large') {
            font-size: 20vw;
            margin-bottom: 15px;
        }
        margin-bottom: 60px;
    }
    .bt{
        display: flex;
        @include backgrounded($bg-orange);
        font-size: 3.6rem;   
        width: 350px;
        height: 80px;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.05em;
        @include respond-to('mobile-large') {
            width: 100%;
            font-size: 3rem;
            padding: 11px 15px;
            height: auto;
        }        
    }
}