footer{
    background-color: $bg-green;
    .wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        @include respond-to('tablet') {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    
    .footer-text{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @include respond-to('tablet') {
            margin-top: 15px;           
            align-items: flex-start;
        }      
    }
    p+p{
        margin-bottom: 0;
    }
    p{
        color: #fff;
        margin-bottom: 5px;
        font-size: 1.4rem;
        font-weight: 700;
        @include respond-to('mobile-large') {
            font-size: 1.2rem;
        }
    }
    .footer-logo{
        @include respond-to('mobile-large') {
            width: 100px;
        }
    }
}