*::selection {
  background: $bg-orange;
}

html,
body {
  height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
}
.wrapper {
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
section{
  .wrapper{
    padding-bottom: 120px;
    padding-top: 120px;
    @include respond-to('tablet') {
      padding-bottom: 60px;
      padding-top: 60px;
    }
    @include respond-to('mobile-large') {
      padding-bottom: 30px;
      padding-top: 30px;
      h2{
        font-size: 2.4rem;
      }
    }
  }
  &.callback .wrapper{
    padding-bottom: 60px;
    padding-top: 60px;
    @include respond-to('mobile-large') {
      padding-bottom: 30px;
    padding-top: 30px;
    }
  }
  &.main-banner .wrapper{
    padding-bottom: 0px;
    padding-top: 0px;
  }
  h2{
    font-size: 5.2rem;
    letter-spacing: 0.05em;
  }
  
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
  font-size: 1.6rem;
  @include respond-to('mobile-large') {
    font-size: 1.4rem;
  }
}

a {
  text-decoration: none;
  color: $color-primary;
}

img {
  display: block;
  width: 100%;  
}

ul {
  padding: 0;
  list-style: none;
}
