section.callback{
    background-color: $bg-orange;
    h2{
        font-family: $font-oswald;
        font-size: 4rem;
        font-weight: 700;
        color: #fff;
        margin-bottom: 15px;
        text-transform: uppercase;
        @include respond-to('mobile-large') {
            font-size: 2.4rem;
            line-height: 1.2;
        }
    }
    .subtitle{
        // font-size: 1.6rem;
        font-weight: 600;
        color: #fff;
        margin-bottom: 30px;
    }
    .acceptance{
        font-size: 1.3rem;
        font-weight: 700;
        color: #fff;
        align-self: center;
        line-height: 1.5;
        @include respond-to('mobile-large') {
            font-size: 1.1rem;
            font-weight: 600;
        }
        
    }
    form{
        display: grid;
        grid-gap: 30px 20px;        
        grid-template-columns: 1fr 1fr;
        @include respond-to('tablet') {
            grid-template-columns: 1fr;
        }
        @include respond-to('mobile-large') {
            grid-gap: 20px 20px;   
          }
    }
    input{
        border: 1px solid #c4c4c4;
    }
    input,button{
        border-radius: 10px;       
        padding: 0 20px;
        height: 50px;
        outline: 0;        
    }
    button{
        border: none;
        background-color: $bg-green;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-family: $font-opensans;
        font-size: 18px;
        font-weight: 700;
    }
}