#territory{
    background-color: $bg-orange;
    .wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        grid-column-gap: 30px;
        @include respond-to('tablet') {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            justify-items: center;
            grid-row-gap: 30px;      
        }
    }
    h2{
        grid-column: 2/3;
        @include backgrounded($bg-green);
        // font-size: 5.2rem;
        max-width: 350px;
        text-align: center;  
        @include respond-to('tablet') {
            grid-column: 1/3;
            grid-row: 1/2;
            width: 100%;
        }      
        letter-spacing: 0.05em;
        @include respond-to('mobile-large') {
            width: 100%;
            font-size: 3rem;
            padding: 11px 15px;
            height: auto;
        }
    }
    .text{
        align-self: center;
        padding: 15px 0;
        @include respond-to('tablet') {            
            grid-column: 1/3;
        }
    }
    p{
        font-weight: 600;
        line-height: 1.4;
        color: #fff;
        // font-size: 1.6rem;
        text-indent: 20px;
        
    }
    .territory__image{
        @include respond-to('tablet') {
            grid-row: 2/3;
            justify-self: center;
            grid-column: 1/3;
        }
    }
}