#contacts{
    .wrapper{
        display: grid;
        grid-template-columns: 1fr 2fr;          
        grid-gap: 30px;   
        @include respond-to('tablet') {
            grid-template-columns: 1fr;
        }     
    }
    h2{        
        width: 100%;
        @include backgrounded($bg-green);
        // font-size: 5.2rem;
        max-width: 540px;
        text-align: center;        
        margin-bottom: 60px;
        letter-spacing: 0.05em;
        @include respond-to('mobile-large') {
            width: 100%;
            font-size: 2.8rem;
            padding: 11px 15px;
            height: auto;
            margin-bottom: 30px;
        }
    }    
    h3{
        color: $bg-green;
        font-family: $font-opensans;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        &.address{
            margin-bottom: 30px;
        }
        span{
            color: #333;
        }
        a{
            color: $bg-green;
        }
    }
    // .map{
    //     min-width: 100%;
    //     min-height: 100%;
    //     overflow: hidden;
    //     border-radius: 50%;
    // }
}