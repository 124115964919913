#services{
    h2{
        @include backgrounded($bg-orange);
        // font-size: 5.2rem;
        max-width: 350px;
        text-align: center;
        margin-bottom: 60px;
        letter-spacing: 0.05em;
        @include respond-to('mobile-large') {
            width: 100%;
            font-size: 2.8rem;
            padding: 11px 15px;
            height: auto;
            margin-bottom: 30px;
        }
    }
    ul{
        width: 100%;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(4,1fr);
        counter-reset: num;
        grid-auto-flow: column;
        @include respond-to('tablet') {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-auto-flow: row;
        }
    }
    li{
        display: flex;
        align-items: center;
        color: $color-primary;        
        &:before{
            content: counter(num); 
	        counter-increment: num;
            margin-right: 30px;
            height: 95px;
            min-width: 95px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-family: $font-oswald;
            font-weight: 600;
            font-size: 5.2rem;
            padding: 30px;
            border-radius: 50%;
            background-color: $bg-green;
            @include respond-to('mobile-large') {
                height: 36px;
                min-width: 36px;
                width: 36px;
                margin-right: 15px;
                font-size: 3.2rem;
            } 
        }
    }
    
    h3{
        font-family: $font-opensans;
        font-weight: 700;
        color: $color-primary;
        font-size: 1.8rem;
        @include respond-to('tablet') {
            font-size: 1.4rem;
        }
    }
    
}